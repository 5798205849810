import mitt from 'mitt';
import { AudioAnalysis } from './analysis/audio_analysis.js';

/**
 * Plays audio streams received in raw PCM16 chunks from the browser
 * @class
 */
export class WavStreamPlayer {
  /**
   * Creates a new WavStreamPlayer instance
   * @param {{sampleRate?: number}} options
   * @returns {WavStreamPlayer}
   */
  constructor({ sampleRate = 44100 } = {}) {
    this.sampleRate = sampleRate;
    this.context = null;
    this.analyser = null;
    this.isPlaying = false;
    this.audioQueue = [];
    this.emitter = mitt();
  }

  /**
   * Connects the audio context and enables output to speakers
   * @returns {Promise<true>}
   */
  async connect() {
    this.context = new AudioContext({ sampleRate: this.sampleRate });
    if (this.context.state === 'suspended') {
      await this.context.resume();
    }

    const analyser = this.context.createAnalyser();
    analyser.fftSize = 8192;
    analyser.smoothingTimeConstant = 0.1;
    this.analyser = analyser;
    return true;
  }

  /**
   * Adds 16BitPCM data to the playback queue
   * @param {ArrayBuffer|Int16Array} arrayBuffer
   * @param {string} [trackId]
   * @returns {Int16Array}
   */
  async add16BitPCM(arrayBuffer, trackId = 'default') {
    if (typeof trackId !== 'string') {
      throw new Error(`trackId must be a string`);
    }
    let buffer;
    if (arrayBuffer instanceof Int16Array) {
      buffer = arrayBuffer;
    } else if (arrayBuffer instanceof ArrayBuffer) {
      buffer = new Int16Array(arrayBuffer);
    } else {
      throw new Error(`argument must be Int16Array or ArrayBuffer`);
    }

    this.audioQueue.push(buffer);
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.emitter.emit('playbackStarted');
      this.playNext();
    }

    return buffer;
  }

  async playNext() {
    if (this.audioQueue.length === 0) {
      this.isPlaying = false;
      this.emitter.emit('playbackEnded');
      return;
    }

    const buffer = this.audioQueue.shift();
    const audioBuffer = await this.decodePCM16(buffer);
    const sourceNode = this.context.createBufferSource();
    sourceNode.buffer = audioBuffer;

    sourceNode.connect(this.analyser);
    this.analyser.connect(this.context.destination);

    sourceNode.start();

    sourceNode.onended = () => {
      this.playNext();
    };
  }

  /**
   * Decodes PCM16 data into an AudioBuffer
   * @param {Int16Array} pcm16Data
   * @returns {Promise<AudioBuffer>}
   */
  async decodePCM16(pcm16Data) {
    const audioBuffer = this.context.createBuffer(
      1,
      pcm16Data.length,
      this.sampleRate
    );
    const float32Data = audioBuffer.getChannelData(0);
    for (let i = 0; i < pcm16Data.length; i++) {
      float32Data[i] = pcm16Data[i] / 32768;
    }
    return audioBuffer;
  }

  /**
   * Gets the current frequency domain data from the playing track
   * @param {"frequency"|"music"|"voice"} [analysisType]
   * @param {number} [minDecibels] default -100
   * @param {number} [maxDecibels] default -30
   * @returns {import('./analysis/audio_analysis.js').AudioAnalysisOutputType}
   */
  getFrequencies(
    analysisType = 'frequency',
    minDecibels = -100,
    maxDecibels = -30
  ) {
    if (!this.analyser) {
      throw new Error('Not connected, please call .connect() first');
    }
    return AudioAnalysis.getFrequencies(
      this.analyser,
      this.sampleRate,
      null,
      analysisType,
      minDecibels,
      maxDecibels
    );
  }

  /**
   * Interrupts playback and clears the audio queue
   * @returns {Promise<null>}
   */
  async interrupt() {
    // Stop current playback and clear queue
    this.audioQueue = [];
    this.isPlaying = false;
    this.emitter.emit('playbackEnded');
    // Implement any additional interrupt logic if needed
    return null;
  }

  on(type, handler) {
    this.emitter.on(type, handler);
  }

  off(type, handler) {
    this.emitter.off(type, handler);
  }
}

globalThis.WavStreamPlayer = WavStreamPlayer;
